import { Injectable } from "@angular/core";
import { BusinessFieldDto } from "@app/api/models";
import { Subject, of } from "rxjs";

@Injectable({
	providedIn: 'root',
})
export class TripBusinessFieldService {
    model: BusinessFieldDto = null;

    private businessFieldSource = new Subject<BusinessFieldDto>();
    businessField$ = this.businessFieldSource.asObservable();

	constructor() {}

    setBusinessField(model: BusinessFieldDto) {
        this.model = model;
        this.businessFieldSource.next(this.model)
    }
}